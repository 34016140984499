<template>
  <div class="box">
    <div class="headline">检查流程</div>
    <div class="second_line">5.1现场检查</div>
    <span class="text"> 进入现场检界面，点击首页【现场检查】，如下图 </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/4.png')"
      :preview-src-list="[require('../../assets/Bankimg/4.png')]"
    >
    </el-image>

    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/5.png')"
      :preview-src-list="[require('../../assets/Bankimg/5.png')]"
    >
    </el-image>
    <span class="text">
      图中列表显示的就是所有现场检查的详细数据，其中可清楚的看到该检查的检查状态
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/6.png')"
      :preview-src-list="[require('../../assets/Bankimg/6.png')]"
    >
    </el-image>
    <div class="second_line">5.1.1、新做一次现场检查</div>
    <span class="text"> 点击“抽取题目”，弹窗如下： </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/7.png')"
      :preview-src-list="[require('../../assets/Bankimg/7.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/8.png')"
      :preview-src-list="[require('../../assets/Bankimg/8.png')]"
    >
    </el-image>
    <span class="text">
      选择《所属公司》，再选择《所属区域》，然后点击“添加范畴”再弹出窗中选中对应的涉及范畴，
      点击“确定”关闭弹窗后输入想要抽取题目的数量，最后点击“确定抽题”完成检查与对应检查详情的添加，
      如果想要只添加检查，不抽题，则可以不点击“添加范畴”。
      添加完现场检查后可直接进入检查详情界面，也可通过双击检查列表中的某一个检查进入，
      界面如下图，该界面可对未抽题的检查继续抽题，也可以点击“新增提问”，在弹出窗填写《问题描述》，
      再点击“确定”完成对自定义问题的添加。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/9.png')"
      :preview-src-list="[require('../../assets/Bankimg/9.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/10.png')"
      :preview-src-list="[require('../../assets/Bankimg/10.png')]"
    >
    </el-image>
    <span class="text">
      检查详情界面可判断该检查项是否符合要求，再上传相应的图片，最后双击“审核意见”单元格并输入《审核意见》，
      完成对该检查项的审核，完成所有检查项的审核后再点击“完成检查”完成对该现场检查计划的检查
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/11.png')"
      :preview-src-list="[require('../../assets/Bankimg/11.png')]"
    >
    </el-image>
    <span class="text"> 点击“打印检查表”弹出如下图，根据需要可自行打印。 </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/12.png')"
      :preview-src-list="[require('../../assets/Bankimg/12.png')]"
    >
    </el-image>
    <span class="text">
      待所有问题审核完后，即可点击“完成审核”来完成该计划的审核。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/13.png')"
      :preview-src-list="[require('../../assets/Bankimg/13.png')]"
    >
    </el-image>
    <span class="text">
      完成审核后会产生对应跟踪项的数据，顺序依次为【发出报告】-【提交措施】-【提交验证】-【确定验证】-【已通过验证】如下图：
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/14.png')"
      :preview-src-list="[require('../../assets/Bankimg/14.png')]"
    >
    </el-image>
    <span class="text">
      可以点击某一行发出报告，也可以点击全部发出报告来一次性发出所有报告
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/15.png')"
      :preview-src-list="[require('../../assets/Bankimg/15.png')]"
    >
    </el-image>
    <span class="text">
      提交措施，输入措施，选择时间点击确定即可，然后直接提交验证，再确定验证时，可选择是否通过，不通过则会重新产生一条跟踪数据。
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/16.png')"
      :preview-src-list="[require('../../assets/Bankimg/16.png')]"
    >
    </el-image>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/17.png')"
      :preview-src-list="[require('../../assets/Bankimg/17.png')]"
    >
    </el-image>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      srcList: "require('../../assets/Bankimg/1.jpg')",
    };
  },
};
</script>
  
  <style  lang="less" scoped>
.box {
  padding-left: 40px;
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding-top: 28px;
    line-height: 45px;
    padding-left: 0px ;
  }
  .second_line {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 0 12px;
  }
  .text {
    color: #141e31;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  img {
  }
}
</style>